<template>
  <div
    class="board-section-empty-placeholder"
    data-empty-placeholder=""
  >
    <div
      class="tc-card-header"
      style="display: none;"
    /><!-- Required for disabling drag on this item -->
    <i class="zmdi zmdi-hc-2x zmdi-chart" />
    <h5 class="message medium-text">
      {{
        hasExistingCards
          ? $pgettext('Empty Section Title — Existing cards', 'Dra in ett Card')
          : $pgettext('Empty Section Title — No cards', 'Lägg till ditt första Card')
      }}
    </h5>
    <p v-if="hasExistingCards">
      {{ $pgettext(
        'Empty Section Body — Existing cards',
        'Eller klicka på ”+ Lägg till Card” för att lägga till det här'
      ) }}
    </p>
    <div class="huge-btns my-2">
      <button
        class="btn btn-lg btn-secondary"
        @click.prevent="openKeyMetricModal"
      >
        <span class="tc-color-brand">
          <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Nyckeltal') }}
        </span>
        <span class="tiny-text text-wrap">
          {{ $pgettext('Description — Key Metric', 'Hitta ditt fokusområde') }}
        </span>
      </button>
      <button
        class="btn btn-lg btn-secondary"
        @click.prevent="openAddQuestionsModal"
      >
        <span class="tc-color-brand">
          <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Fråga') }}
        </span>
        <span class="tiny-text text-wrap">
          {{ $pgettext('Description — Question', 'Dyk in i formulärfrågor') }}
        </span>
      </button>
      <button
        class="btn btn-lg btn-secondary"
        @click.prevent="openAddQuestionsModal({ isGoal:true })"
      >
        <span class="tc-color-brand">
          <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Mål') }}
        </span>
        <span class="tiny-text text-wrap">
          {{ $pgettext('Description — Goal', 'Följ upp dina framsteg') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useCardModals } from 'Composables/useCardModals';

const props = defineProps({
  hasExistingCards: {
    type: Boolean,
    default: false,
  },
  boardId: Number,
  sectionId: Number,
});

const emit = defineEmits(['add:cards']);

const {
  openAddQuestionsModal,
  openKeyMetricModal,
} = useCardModals({
  boardId: props.boardId,
  sectionId: props.sectionId,
  emit,
});
</script>
