import stepFormAPI from 'API/stepForms';
import { groupby } from 'Utils/array';
import { translateTerm } from 'Utils/general';
import { labelHit, markLabel } from 'Utils/tcselectHelpers';

export default {
  state: {
    segmentForms: {}, // All forms under segmentId
    segmentFormsTiny: {}, // All formsTiny under segmentId
  },
  mutations: {
    ADD_SEGMENT_STEP_FORM(state, { segmentId, promise }) {
      state.segmentForms[segmentId] = promise;
    },
    ADD_SEGMENT_STEP_FORM_TINY(state, { segmentId, promise }) {
      state.segmentFormsTiny[segmentId] = promise;
    },
    CLEAR_STEP_FORMS_CACHE(state) {
      state.segmentForms = {};
      state.segmentFormsTiny = {};
    },
  },
  getters: {
    // segmentFormsTinyOptions: (state, getters) => async (term = '') => {
    //   if (!state.segmentFormsTiny?.[getters.segmentId]) { Can’t dispatch in getters
    //     dispatch('fetchStepFormsBySegmentId', { segmentId: getters.segmentId, tiny: true });
    //     return [];
    //   }
    //   return state.segmentFormsTiny[getters.segmentId]
    //     .then((formsTiny) => formsTiny
    //       .map((form) => console.log('form', form) || ({ value: form.customer_proxy_id, label: form.customer_proxy_internal_name || form.customer_proxy_name }))
    //       .filter(labelHit(term))
    //       .map(markLabel(term)));
    // },
    segmentLevels: async (state, getters) => (state.segmentFormsTiny?.[getters.segmentId] ?? Promise.resolve([]))
      .then(groupby('level'))
      .then((formsTinyMap) => [...formsTinyMap.keys()]),
    // Make sure to `if (!state.segmentFormsTiny?.[getters.segmentId]) dispatch('fetchStepFormsBySegmentId', { segmentId: getters.segmentId, tiny: true });`
    segmentLevelOptions: (state, getters) => async (term = '') => getters.segmentLevels
      .then((levels) => levels
        .map((level) => ({ value: level, label: translateTerm(level) }))
        .filter(labelHit(term))
        .map(markLabel(term))),
  },
  actions: {
    fetchForms({ dispatch, getters }, { tiny = false } = {}) {
      return dispatch('fetchStepFormsBySegmentId', { segmentId: getters.segmentId, tiny })
        .then(groupby('level'))
        .then(Array.from)
        .then((forms) => forms.map((form) => form[1]));
    },
    fetchFormsByLevel({ getters }, { level, tiny = false } = {}) {
      return stepFormAPI.list(getters.segmentId, { tiny, step: level });
    },
    fetchStepFormsBySegmentId({ commit, state }, { segmentId, tiny = false } = {}) {
      if (segmentId) {
        if (!tiny && state.segmentForms[segmentId]) {
          return state.segmentForms[segmentId];
        }
        if (tiny && state.segmentFormsTiny[segmentId]) {
          return state.segmentFormsTiny[segmentId];
        }

        let promise = stepFormAPI.list(segmentId, { tiny, paginated: false });
        commit(tiny ? 'ADD_SEGMENT_STEP_FORM_TINY' : 'ADD_SEGMENT_STEP_FORM', { segmentId, promise });
        return promise;
      }
      return Promise.reject(new Error('Could not get segment stepforms without segmentId.'));
    },
    clearStepFormsCache({ commit }) {
      commit('CLEAR_STEP_FORMS_CACHE');
    },
  },
};
