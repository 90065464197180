<template>
  <div
    :tabindex="isOpen ? 0 : -1"
    @keyup.stop.esc="close(checkChildConfirmExit())"
  >
    <transition-group
      name="slide-down-fade"
      tag="div"
      class="modalception"
    >
      <div
        v-for="(child, i) in allOpenModals"
        :key="`${child.name}-${i}`"
        class="modal-overlay"
        :class="{
          centered: child.position === 'center',
          active: i === allOpenModals.length - 1,
        }"
        role="dialog"
        aria-modal="true"
        @click.self="close(child.confirmExit)"
        @keyup.esc="close(child.confirmExit)"
      >
        <div
          class="modal-dialog"
          :class="{
            'modal-dialog-large': child.size === 'large',
            'modal-dialog-xlarge': child.size === 'xlarge',
          }"
          @keypress.esc="close(child.confirmExit)"
        >
          <i
            v-if="child.name !== 'dialogBox'"
            class="modal-exit-icon zmdi zmdi-close btn-text"
            tabindex="0"
            @click="close(child.confirmExit)"
            @keypress.esc.self="close(child.confirmExit)"
            @keypress.enter.self="close(child.confirmExit)"
          />
          <component
            :is="child?.component || 'DynamicImportError'"
            class="modal-content"
            v-bind="child?.dataObject?.props || {}"
            @vue:mounted="setFocus"
            v-on="child?.dataObject?.on || {}"
          />
        <!-- :close="close" -->
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import dynamicImport from 'Utils/dynamicImport';
import DynamicImportError from 'Components/parts/dynamic-import/DynamicImportError';

export default {
  name: 'Modalception',
  components: {
    DynamicImportError,
  },
  data() {
    return {
      localModals: [],
    };
  },
  computed: {
    ...mapGetters([
      'activeModal',
      'allOpenModals',
    ]),
    isOpen() {
      return this.allOpenModals.length > 0;
    },
  },
  beforeUnmount() { // Doesn’t happen, because <modalception> isn’t destroyed
    if (this.isOpen) this.close();
    this.closeAllModals();
    this.$$eventBus.$off('modal-open-upgrade', this.openUpgradeModal);
    this.$$eventBus.$off('modal-open-confetti', this.openConfettiModal);
  },
  created() {
    this.$$eventBus.$on('modal-open-upgrade', this.openUpgradeModal);
    this.$$eventBus.$on('modal-open-confetti', this.openConfettiModal);
  },
  methods: {
    ...mapActions([
      'openModal',
      'closeModal',
      'closeAllModals',
    ]),
    checkChildConfirmExit() {
      return this.allOpenModals?.[this.allOpenModals.length - 1]?.confirmExit;
    },
    setFocus() {
      this.$nextTick(() => { this.getFirstFocusableElementInComponent(this?.$el?.querySelector('.modal-content'))?.focus(); });
    },
    getFirstFocusableElementInComponent(component) {
      if (!component) return null;
      const focusableItems = [...component.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
      )]
        .filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
      const firstFocusableItem = focusableItems[0];
      return firstFocusableItem;
    },
    close: debounce(function debouncedFn(confirmExit = false) {
      this.closeModal({ confirmExit });
    }, 250, { leading: true, trailing: false }),
    // ? Reusable general modals
    openUpgradeModal(...props) {
      const GeneralUpgradeDetails = dynamicImport(() => import(/* webpackChunkName: "GeneralUpgradeDetails", webpackPrefetch: true */ 'Components/parts/details/GeneralUpgradeDetails'));
      return this.openModal({
        name: 'GeneralUpgradeDetails',
        component: GeneralUpgradeDetails,
        dataObject: { props: props[0] },
        size: 'xlarge',
        position: 'center',
      });
    },
    openConfettiModal(signedUpForTrial) {
      const ConfettiScreen = dynamicImport(() => import(/* webpackChunkName: "ConfettiScreen", webpackPrefetch: true */ 'Components/parts/details/ConfettiScreen'));
      return this.openModal({
        name: 'ConfettiScreen',
        component: ConfettiScreen,
        dataObject: { props: { signedUpForTrial } },
        position: 'center',
      });
    },
  },
};
</script>
