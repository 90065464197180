<template>
  <div>
    <div class="step-form-list mb-5">
      <step-form-list-single
        v-for="step in allSteps"
        :key="step"
        :step="step"
      />
    </div>
    <div
      v-if="!isLoadingForms && availableSurveys?.length"
      class="page-content-title activatewall-text"
    >
      <span class="h2">
        <i class="zmdi zmdi-forms-page" />
      </span>
      <span>
        <h2
          class="cursor-pointer"
          @click="openSurveyModal()"
        >{{ $gettext('Tillgängliga formulär') }}</h2>
      </span>
    </div>
    <div class="step-form-list badge-activatewall-outline badge-activatewall-outline--thick">
      <div
        v-for="(step, index) in availableSurveys"
        :key="index"
        class="step-form-list-single justify-space-between"
      >
        <div class="mb-2">
          <p class="link mb-0">
            {{ step.name }}
          </p>
          <p class="subtle-text small-text">
            {{ step.description }}
          </p>
        </div>
        <button
          class="btn btn-primary"
          @click="openSurveyModal(step.step)"
        >
          {{ $gettext('Aktivera Formuläret') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import dynamicImport from 'Utils/dynamicImport';
import useSurveyList from 'Composables/useSurveyList';
import { store } from '@/store';
import StepFormListSingle from './StepFormListSingle';

const { hardCodedSurveysSorted, isLoadingForms } = useSurveyList();

const props = defineProps({
  allSteps: {
    type: Array,
    required: true,
  },
});

const availableSurveys = computed(() => hardCodedSurveysSorted.value.filter((survey) => survey?.active === false && !props.allSteps.includes(survey.step)));
const openSurveyModal = (showSurvey = '') => {
  const AddSurveyDetails = dynamicImport(() => import(/* webpackChunkName: "AddSurveyDetails", webpackPrefetch: true */ 'Components/parts/details/AddSurveyDetails'));
  store.dispatch('openModal', {
    name: 'AddSurveyDetails',
    component: AddSurveyDetails,
    dataObject: { props: { showSurvey } },
    size: 'xlarge',
  });
};
</script>
