<template>
  <div
    v-if="!proxies.length && loading || loading"
    v-tc-loader-spinner="loading"
    class="step-form-list-single on-hover on-hover-slide-right tc-loader-spinner-wrapper"
  >
    <BoardAreaSkeleton />
  </div>
  <div
    v-else-if="proxies.length && !loading"
    v-tc-loader-spinner="loading"
    class="step-form-list-single on-hover on-hover-slide-right tc-loader-spinner-wrapper"
  >
    <div
      class="step-form-list-single-body"
      @mouseover="showCount = true"
      @mouseout="showCount = false"
    >
      <div class="step-form-list-single-header">
        <router-link
          class="block mb-0"
          tabindex="0"
          :to="{ name: 'form', params: { level: step}}"
        >
          <span v-tooltip.top-start="{ content: levelTooltip }">
            {{ translateTerm(step) }}
          </span>
          <i class="ml-0.5 on-hover-visible zmdi zmdi-arrow-right" />
        </router-link>
      </div>
      <VMenu
        v-if="isStepPaused"
        :distance="8"
        placement="top-start"
      >
        <span class="subtle-text">
          <i
            class="zmdi zmdi-pause-circle zmdi-hc-lg mr-0.5"
            :title="$gettext('Pausad')"
          />
          {{ $gettext('Pausad') }}
        </span>

        <template #popper>
          <p class="mb-0.5">
            {{ $pgettext(
              'Popper — Inactive step', 'Detta formulär är pausat, klicka för att be oss aktivera det i chatten'
            ) }}
          </p>
          <button
            class="btn btn-thin btn-block btn-activatewall medium-text mb-1"
            @click="reactivateStep(step)"
          >
            <i class="zmdi zmdi-play-circle-outline zmdi-hc-lg mr-0.5" />
            <span>{{ $pgettext('Button — Reactivate step', 'Återaktivera') }}</span>
          </button>
        </template>
      </VMenu>
      <div
        v-if="hasData"
        class="step-form-response-rates flex align-items-baseline justify-space-between"
      >
        <div>
          <p class="tc-color-blue-dark mb-0 flex gap-1 align-center">
            <span v-if="showCount">{{ submittedStats }}</span>
            <strong v-else>{{ roundNumber(stats.respond_perc * 100) }}% </strong>
            <span v-if="!showCount">({{ global ? `${roundNumber(global.respond_perc * 100)}%` : '–' }})</span>
          </p>
          <p
            v-if="showCount"
            class="subtle-text small-text mb-0"
          >
            {{ $gettext('Antal') }}
          </p>
          <p
            v-else
            class="subtle-text small-text mb-0"
          >
            {{ $gettext('Svarsfrekvens') }} {{ $gettext('(globalt)') }}
          </p>
        </div>
        <div>
          <p class="tc-color-blue-dark mb-0 flex gap-1 align-center">
            <strong>{{ roundNumber(stats.open_perc * 100) }}%</strong>
            <span v-tooltip="global ? '' : notEnoughGlobalData">
              ({{ global ? `${roundNumber(global.open_perc * 100)}%` : '–' }})
            </span>
          </p>
          <p class="subtle-text small-text mb-0">
            {{ $gettext('Öppnat formuläret') }} {{ $gettext('(globalt)') }}
          </p>
        </div>
        <div>
          <p
            class="tc-color-blue-dark mb-0"
            v-text="stats.applicant_count ?? '–'"
          />
          <p
            class="subtle-text small-text mb-0"
            v-text="$gettext('Skickade formulär')"
          />
        </div>
      </div>
      <div v-else>
        <p class="mb-0 subtle-text small-text">
          {{ $gettext('Det finns inte tillräckligt med data för att visa svarsfrekvensen.') }}
        </p>
        <p class="subtle-text small-text">
          {{ $gettext('Testa att ändra datumfiltreringen eller vänta på att mer data samlas in.') }}
        </p>
      </div>

      <div class="mt-1">
        <small
          v-tooltip="surveyTooltip"
          class="subtle-text cursor-help"
        >
          {{ $gettext('Formulär') }} <i class="zmdi zmdi-info ml-0.5" />
        </small>
        <div
          class="step-form-select-wrapper"
        >
          <tc-select
            v-model="proxy"
            :options="proxies"
            :placeholder="$gettext('Välj ett formulär att titta på.')"
          />
          <button
            v-if="!proxy?.length"
            class="btn"
            :disabled="!proxy?.length"
            butt
          >
            {{ $gettext('Visa formulär') }}
            <i class="ml-1 zmdi zmdi-open-in-new" />
          </button>
          <a
            v-else
            class="btn btn-primary"
            :href="`${BASE_URL}${proxy[0]?.test_url}` || ''"
            :title="proxy[0]?.label || ''"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $gettext('Visa formulär') }}
            <i class="ml-1 zmdi zmdi-open-in-new" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isArray, isEmpty } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { requestData } from 'API/data';
import { BASE_URL } from 'Utils/urls';
import { translateTerm, roundNumber, equalLiteral } from 'Utils/general';
import BoardAreaSkeleton from 'Components/parts/board/BoardAreaSkeleton';

export default {
  components: {
    BoardAreaSkeleton,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formTiny: [],
      BASE_URL,
      gettingStats: false,
      fetchingTiny: false,
      showCount: false,
      stats: null,
      global: null,
      proxy: [],
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentFilter',
      'customerId',
    ]),
    loading() {
      return this.gettingStats || this.fetchingTiny;
    },
    proxies() {
      if (this.step && this.formTiny.length) {
        return this.formTiny?.map((form) => ({
          ...((!form.active && { icon: 'zmdi zmdi-pause-circle' }) ?? {}),
          value: form.customer_proxy_id,
          label: `${form.customer_proxy_name}${this.appendInternalName({ name: form.customer_proxy_name, internal_name: form.customer_proxy_internal_name })}`,
          test_url: form.test_url,
          ...((!form.active && { description: this.$pgettext(
            'Tooltip — Inactive stepform',
            '”%{survey}”-formuläret är pausat. ',
            { survey: form?.customer_proxy_internal_name },
          ) })),
        })) ?? [];
      }
      return [];
    },
    notEnoughGlobalData() {
      return this.$pgettext('Tooltip - Not enough global data', 'Det finns inte tillräckligt med global data inom tidsperioden');
    },
    hasData() {
      return this.stats?.respond_perc != null;
    },
    isStepPaused() {
      return this.formTiny?.every((survey) => survey?.active === false);
    },
    submittedStats() {
      const { responded_count } = this.stats;
      if ((responded_count ?? null) === null) return '';

      return this.$gettextInterpolate(
        this.$pgettext('In showCount on Forms page', '%{stats} st'),
        { stats: responded_count },
      );
    },
    levelTooltip() {
      return this.$pgettext('Tooltip — Forms level', 'Visa alla frågor och svar från detta formulär');
    },
    surveyTooltip() {
      return this.$pgettext('Tooltip — Survey', 'Välj ett formulär att titta på.');
    },
  },
  watch: {
    proxies(newVal, oldVal) {
      if (newVal.length && isEmpty(oldVal)) this.proxy = [newVal?.[0]];
    },
    formTiny(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.go(this.segmentFilter);
    },
    segmentFilter(newFilter, oldFilter) {
      if (!equalLiteral(newFilter, oldFilter)) this.go(newFilter);
    },
  },

  created() {
    this.getTinyForm();
  },

  methods: {
    ...mapActions(['fetchFormsByLevel']),
    isEmpty,
    translateTerm,
    roundNumber,
    reactivateStep(level) {
      const step = translateTerm(level);
      if (window?.Intercom) window.Intercom('showNewMessage', this.$pgettext('Intercom - reactivate step chat', 'Hej! Jag vill återaktivera formuläret %{step}. ', { step }));
    },
    appendInternalName(proxyObj) {
      if (proxyObj?.internal_name && proxyObj.name !== proxyObj.internal_name) return ` (${proxyObj.internal_name})`;
      return '';
    },
    getGlobalQuery() {
      const { date } = this.segmentFilter;
      return [{
        filter: {
          step: { name: this.step },
          date,
        },
      }];
    },
    getQuery() {
      const { date } = this.segmentFilter;
      return [{ filter: {
        step: { name: this.step },
        date,
        segment: { segment_id: this.segmentId },
        customer_proxy: { customer_id: this.customerId },
      } }];
    },
    responseGlobalRequest() {
      return requestData(this.getGlobalQuery(), 'response/global/').then((r) => r?.[0] || []);
    },
    responseRequest() {
      return requestData(this.getQuery(), 'response/').then((r) => r?.[0] || []);
    },
    async getStats(filter) {
      if (this.segmentId && this.step) {
        let promises = [];
        promises.push(this.responseRequest());
        promises.push(this.responseGlobalRequest());
        return Promise.all(promises);
      }
      return Promise.resolve();
    },
    setStats(stats) {
      this.stats = stats[0];
      this.global = !isArray(stats[1]) ? stats[1] : null;
      return stats;
    },
    go(filter) {
      this.stats = null;
      this.gettingStats = true;
      let promiseAll = [this.getStats(filter).then(this.setStats)];
      return Promise.all(promiseAll)
        .then(() => { this.gettingStats = false; })
        .catch((error) => error(`[TC] ${error}`));// eslint-disable-line no-console
    },
    async getTinyForm() {
      this.fetchingTiny = true;
      let response = [];
      try {
        response = await this.fetchFormsByLevel({ level: this.step, tiny: true });
      } catch (error) {
        console.error(this.$pgettext('Error — Stepforms getTinyForm', 'Kunde inte hämta formulär'));// eslint-disable-line no-console
        response = [];
      } finally {
        this.formTiny = response?.results ?? [];
        this.fetchingTiny = false;
      }
    },
  },
};
</script>
