<template>
  <div>
    <header
      ref="formHeaderEl"
      v-element-size="onHeaderResize"
      class="page-content-header page-content-header-fixed"
      :class="{ shadowed: hasScrolled }"
    >
      <div class="hgroup mt-1 mb-0">
        <h2>
          <i class="zmdi zmdi-forms-page-single mr-0.5" />
          <span v-html="translateTerm(level)" />
        </h2>
      </div>
      <board-filters
        :context-metadata="ctxMetadata"
        @update:metadata="ctxMetadata = $event"
      />
    </header>
    <hr
      ref="formHeaderOffsetEl"
      style="margin-top: var(--header-offset-height, 66px)"
    >
    <ul
      v-if="questions.length > 0"
      class="tc-questions-list"
    >
      <li
        v-for="(question, index) in questions"
        :id="`questionId-${question.id}`"
        :key="question.id"
        class="tc-questions-item"
      >
        <show-block
          :class="showClasses(question, index)"
          :question="question"
          :questions="questions"
        />
        <query-result
          v-if="cards[question.id] !== undefined"
          v-model:card="cards[question.id]"
          :context-filter="ctxMetadata.filter"
          :context-benchmark="ctxMetadata.benchmark"
          :context-compare="ctxMetadata.compare"
          :actions="['editButton','downloadPng']"
          is-in-board
          temporary-card
          :data-download-card="question.id"
          @download-card-png="setupDownloadCardOpts(question.id)"
        />
      </li>
    </ul>
    <BoardAreaSkeleton
      v-else-if="loading"
      :sections-amount="3"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useWindowScroll } from '@vueuse/core';
import { vElementSize } from '@vueuse/components'; // eslint-disable-line import/no-extraneous-dependencies
import * as htmlToImage from 'html-to-image';
import { captureException } from '@/plugins/sentry';
import { CUSTOMER_PLAN_UPGRADE_REASONS } from 'API/customer';
import { cardFromQuestion } from 'API/cards';
import dynamicImport from 'Utils/dynamicImport';
import eventBus from 'Utils/eventBus';
import { questionInShow, translateTerm, filterOutUnanswerableQuestion } from 'Utils/general';
import BoardFilters from 'Components/parts/filters/BoardFilters';
import ShowBlock from 'Components/parts/step-form/ShowBlock';
import QueryResult from 'Components/parts/explore/QueryResult';
import BoardAreaSkeleton from 'Components/parts/board/BoardAreaSkeleton';
import { destroyAllTooltips } from 'Components/parts/widgets/PerformantTooltip';
import setSiteTitle from '@/router/siteTitle';
import gettext from '@/gettext';

const { $pgettext } = gettext;

export default {
  name: 'StepForm',
  components: {
    QueryResult,
    BoardFilters,
    ShowBlock,
    BoardAreaSkeleton,
  },
  directives: {
    elementSize: vElementSize,
  },
  beforeRouteEnter(to, from, next) {
    if (to?.params?.level) {
      setSiteTitle({ meta: {
        siteTitle: () => $pgettext('siteTitle — forms/:level dynamic', '%{level} – Formulär', { level: translateTerm(to.params.level) }),
      } });
    }
    next();
  },
  props: {
    level: String,
  },
  setup() {
    const { y: windowScrollY } = useWindowScroll();
    return { windowScrollY };
  },
  data() {
    return {
      ctxMetadata: {
        filter: {
          date: { type: 'relative', offset: null, span: { months: 12 } },
        },
        level: this.level,
        benchmark: {},
        compare: null,
      },
      cards: {},
      questions: [],
      stepForms: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'showPaywalls',
      'customerPlanCanStartFreeTrial',
    ]),
    hasScrolled() { return this.windowScrollY >= 10; },
  },
  watch: {
    level(level) {
      this.go(level).catch(() => { });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.go(this.level).catch(() => { });
    });
  },
  created() {
    eventBus.$on('update:temporary-card-metadata', ({ metadata }) => {
      this.cards[metadata?.question].metadata = {
        ...this.cards?.[metadata?.question]?.metadata, ...metadata,
      };
    });
  },
  beforeUnmount() {
    eventBus.$off('update:temporary-card-metadata');
    destroyAllTooltips();
  },
  methods: {
    translateTerm,
    ...mapActions([
      'getQuestionsByLevel',
      'fetchStepFormsBySegmentId',
      'removeDownloadingCard',
      'openModal',
      'notify',
    ]),
    openPaywall() {
      const GeneralUpgradeDetails = dynamicImport(() => import(/* webpackChunkName: "GeneralUpgradeDetails", webpackPrefetch: true */ 'Components/parts/details/GeneralUpgradeDetails'));
      return this.openModal({
        name: 'GeneralUpgradeDetails',
        component: GeneralUpgradeDetails,
        dataObject: { props: {
          upgradeReason: CUSTOMER_PLAN_UPGRADE_REASONS.unlimited_downloads,
          canStartFreeTrial: this.customerPlanCanStartFreeTrial,
        } },
        size: 'xlarge',
        position: 'center',
      });
    },
    setupDownloadCardOpts(id) {
      if (this.showPaywalls) {
        this.removeDownloadingCard(id ?? null);
        this.openPaywall();
        return;
      }
      const node = document.querySelector(`[data-download-card="${id}"`);
      const options = { filter: this.excludeClassesFilter, pixelRatio: 2 };
      this.downloadCard(node, options, id);
    },
    excludeClassesFilter(node) {
      const exclusionClasses = ['hide-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    },
    async downloadCard(node, options, id) {
      try {
        const dataUrl = await htmlToImage.toPng(node, options);
        let fileName = this.cards[id].metadata.name || this.cards[id].metadata.graphType.selected || 'chart';
        fileName += `-${id ?? ''}`;
        const link = document.createElement('a');
        link.download = `${fileName}.png`;
        link.href = dataUrl;
        link.click();
        link.remove();
      } catch (error) {
        captureException('[TC] Couldn’t download card:', error);
        this.notify({
          type: 'slow',
          level: 'error',
          text: this.$gettext('Kunde inte ladda ner cardet.'),
        });
      } finally { this.removeDownloadingCard(id); }
    },
    showClasses(question, index) {
      if (question.show && this.questions[index - 1]) {
        return {
          'has-show-to-previous': questionInShow(
            this.questions[index - 1].id,
            question.show,
          ),
        };
      }
      return false;
    },
    setQuestions(questions) {
      const filteredQuestions = questions
        .filter((question) => filterOutUnanswerableQuestion(question, this.level, this.stepForms));

      this.cards = filteredQuestions.reduce((acc, question) => {
        const obj = { [question.id]: cardFromQuestion(question, this.level) };
        return { ...acc, ...obj };
      }, {});
      this.questions = filteredQuestions;
      return filteredQuestions;
    },
    go(level) {
      if (level === undefined) {
        return Promise.resolve();
      }
      this.ctxMetadata.level = level;
      this.loading = true;
      this.questions = [];
      return this.getQuestionsByLevel(level)
        .then(async (data) => {
          const forms = await this.fetchStepFormsBySegmentId({ segmentId: this.segmentId })
            .catch(() => { });
          this.stepForms = forms;
          return data;
        })
        .then(this.setQuestions)
        .then(() => {
          this.loading = false;
        })
        .catch(() => { });
    },
    onHeaderResize({ width, height }) {
      let currentWidth = window?.document?.body?.getBoundingClientRect?.()?.width ?? width;
      let currentHeight = height || 90;
      let offsetPx = 16;
      if (currentWidth > 992) { // Breakpoint large, desktop view
        offsetPx = 24;
      }

      document.documentElement.style.setProperty('--header-offset-height', `${Math.ceil(currentHeight - offsetPx)}px`);
      if (this.$refs?.formHeaderEl) { // ? Set header height so popper can calculate correct position
        const formHeaderContainerRect = this.$refs.formHeaderEl.getBoundingClientRect();
        document.documentElement.style.setProperty('--header-height', `${Math.ceil(formHeaderContainerRect.height)}px`);
      }
    },
  },
};
</script>
