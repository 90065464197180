<template>
  <nav
    class="nav-main"
    :aria-label="$pgettext('ARIA - Main nav', 'Huvudmeny')"
  >
    <ul class="sidebar-list">
      <li>
        <a
          id="ariaReportsAccordionBtn"
          href="#"
          tabindex
          class="block-link"
          :class="{ 'active': routesForReports.includes(routeName) }"
          :aria-expanded="activeAccordions.includes('reports')"
          aria-controls="ariaReportsAccordion"
          :aria-label="$pgettext('ARIA - Reports Accordion Btn', 'Visa rapporter')"
          @click.prevent.stop="toggleAccordion('reports')"
        >
          <span>
            <i
              class="zmdi zmdi-chart"
              aria-hidden
            />
            <span>{{ $gettext('Rapporter') }}</span>
          </span>
          <div>
            <button
              v-tooltip="$pgettext('Tooltip - Add report quick','Ny rapport')"
              class="btn btn-chevron"
              :aria-label="$pgettext('ARIA - Report Add Btn', 'Ny rapport')"
              @click.prevent.stop="openAddBoardModal"
            >
              <i class="zmdi zmdi-plus" />
            </button>
            <button class="btn btn-chevron ml-2">
              <i
                class="zmdi zmdi-chevron-down"
                :class="{ 'active': activeAccordions.includes('reports') }"
                aria-hidden
              />
            </button>
          </div>
        </a>
        <div v-auto-animate>
          <BoardList
            v-if="activeAccordions.includes('reports')"
            id="ariaReportsAccordion"
            role="region"
            aria-labelledby="ariaReportsAccordionBtn"
            aria-hidden
          />
        </div>
      </li>
      <li>
        <router-link
          :to="{ name: 'forms' }"
          tabindex
          class="block-link"
          :class="{ 'active': routesForSurveys.includes(routeName) }"
          @click="() => {
            toggleAccordion('surveys');
            closeSidebar();
          }"
        >
          <span>
            <i
              class="zmdi zmdi-forms-page"
              style="margin: 0 3px 0 -3px;"
              aria-hidden
            />
            <span>{{ $gettext('Formulär') }}</span>
          </span>
          <div>
            <button
              v-tooltip="$pgettext('Tooltip - Add survey quick','Lägg till formulär')"
              class="btn btn-chevron"
              :aria-label="$pgettext('ARIA - Surveys Add Btn', 'Lägg till formulär')"
              @click.prevent.stop="() => openSurveyModal()"
            >
              <i class="zmdi zmdi-plus" />
            </button>
            <button
              id="ariaSurveysAccordionBtn"
              class="btn btn-chevron ml-2"
              :aria-expanded="activeAccordions.includes('surveys')"
              aria-controls="ariaSurveysAccordion"
              :aria-label="$pgettext('ARIA - Surveys Accordion Btn', 'Visa formulär')"
              @click.prevent.stop="toggleAccordion('surveys')"
            >
              <i
                class="zmdi zmdi-chevron-down"
                :class="{ 'active': activeAccordions.includes('surveys') }"
                aria-hidden
              />
            </button>
          </div>
        </router-link>
        <div v-auto-animate>
          <ul v-if="!tinyStepFormsLoading && tinyStepForms.length === 0 && activeAccordions.includes('surveys')">
            <li>
              <p
                v-tooltip.right="{
                  content: $pgettext(
                    'Tooltip — Sidebar surveys none available',
                    'Detta segment har inga formulär än. Vänta tills ni fått svar på något formulär eller byt segment'
                  ),
                  container: 'body',
                }"
                class="block-link"
                aria-disabled="true"
              >
                {{ $pgettext(
                  'Message — Sidebar surveys none available',
                  'Har inga formulär än'
                ) }}
                <i class="zmdi zmdi-help-outline" />
              </p>
            </li>
          </ul>
          <ul
            v-else-if="activeAccordions.includes('surveys')"
            id="ariaSurveysAccordion"
            role="region"
            aria-labelledby="ariaSurveysAccordionBtn"
            @click="closeSidebar"
          >
            <template v-if="tinyStepFormsLoading">
              <li
                v-for="(step, index) in customerAllSteps"
                :key="index"
                class="sidebar-placeholder-text my-0"
              >
                <span
                  class="tc-loading-text tc-loading-text-on-color"
                  :class="{
                    'inline': !step,
                    'size-inherit': !step,
                    'size-xsmall': step && index % 2 === 0,
                    'size-small': step && index % 2 !== 0,
                  }"
                >{{ translateTerm(step) || 'Loading…' }}</span>
              </li>
            </template>
            <template v-else>
              <li
                v-for="tinyStepForm in sortedTinyStepForms"
                :key="tinyStepForm[0].level"
              >
                <router-link
                  tabindex
                  class="block-link"
                  :to="{ name: 'form', params: { level: tinyStepForm[0].level }}"
                >
                  {{ translateTerm(tinyStepForm[0].level) }}
                </router-link>
              </li>
            </template>
            <li>
              <button
                tabindex
                class="btn btn-link strong block-link block-link-bulleted subtle-text"
                @click.prevent="() => openSurveyModal()"
              >
                <i class="zmdi zmdi-plus subtle-text" />
                <span class="subtle-text">{{ $pgettext('Sidebar surveys - add survey','Utforska formulär') }} </span>
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <router-link
          :to="{ name: 'applicants' }"
          tabindex
          class="block-link"
          @click="closeSidebar()"
        >
          <span>
            <i
              class="zmdi zmdi-face"
              style="margin: 0 1px 0 -1px;"
              aria-hidden
            />
            <span>{{ $gettext('Feedback') }}</span>
          </span>
        </router-link>
      </li>
      <li>
        <router-link
          v-tooltip.right="{
            content: !hasActivatedFollowUp ? $pgettext('Tooltip — NavMain follow-up', 'Ej aktiverat') : null,
            container: 'body',
          }"
          :to="{ name: 'follow-up' }"
          tabindex
          class="block-link"
          :aria-disabled="!hasActivatedFollowUp"
          @click="closeSidebar()"
        >
          <span>
            <i
              class="zmdi zmdi-comments"
              aria-hidden
            />
            <span>{{ $gettext('Follow up') }}</span>
          </span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'notes' }"
          tabindex
          class="block-link"
          :class="{ active: routesForNotes.includes(routeName) }"
          @click="closeSidebar()"
        >
          <span>
            <i
              class="zmdi zmdi-assignment"
              aria-hidden
            />
            <span>{{ $gettext('Anteckningar') }}</span>
          </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { translateTerm } from 'Utils/general';
import eventBus from 'Utils/eventBus';
import dynamicImport from 'Utils/dynamicImport';
import { getSurveysInOrder } from 'Utils/survey';
import BoardList from 'Components/parts/board/BoardList';
import { store } from '@/store';

const hasActivatedFollowUp = computed(() => store.getters.customerUsedFeatures?.followup || false);
const customerAllSteps = computed(() => store.getters.customerAllSteps);

const activeAccordions = ref([]); // ['reports', 'surveys'];
const openAccordion = (choice) => activeAccordions.value.push(choice);
const closeAccordion = (currIdx, closeAll = false) => {
  if (closeAll) activeAccordions.value = [];
  else if (currIdx > -1) activeAccordions.value.splice(currIdx, 1);
};
const toggleAccordion = (choice, closeOthers = true) => {
  const currIdx = activeAccordions.value.indexOf(choice);
  if (currIdx > -1) {
    closeAccordion(currIdx, closeOthers);
  } else {
    if (closeOthers) activeAccordions.value = [];
    openAccordion(choice);
  }
};
const closeSidebar = () => eventBus.$emit('close-sidebar');

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route?.name);
const routesForReports = ['overview', 'board', 'report'];
const routesForSurveys = ['form', 'forms', 'surveys'];
const routesForNotes = ['notes', 'note'];
const setAccordionFromRoute = (theRouteName = routeName.value) => { // Could add a replaceOrPush prop
  if (routesForReports.includes(theRouteName)) activeAccordions.value = ['reports'];
  else if (routesForSurveys.includes(theRouteName)) activeAccordions.value = ['surveys'];
};
watch(routeName, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) setAccordionFromRoute(newVal);
});

const tinyStepFormsLoading = ref(false);
const tinyStepForms = ref([]);
const getTinyStepForms = async () => {
  try {
    tinyStepFormsLoading.value = true;
    return await store.dispatch('fetchForms', { tiny: true });
  } catch (error) {
    // Promise.reject(new Error(this.$pgettext('Error — Stepforms catch load', 'Kunde inte hämta formulär')));
    return [];
  } finally {
    tinyStepFormsLoading.value = false;
  }
};
watch(() => store.getters.segmentId, async (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) tinyStepForms.value = await getTinyStepForms();
});
onBeforeMount(async () => {
  setAccordionFromRoute();
  tinyStepForms.value = await getTinyStepForms();
});

const sortedTinyStepForms = computed(() => {
  if (!tinyStepForms.value.length) return [];
  const all_steps = tinyStepForms.value.map((step) => step[0].level);
  const stepOrder = getSurveysInOrder(all_steps);
  return [...tinyStepForms.value].sort((a, b) => {
    const aIdx = stepOrder.indexOf(a?.[0]?.level);
    const bIdx = stepOrder.indexOf(b?.[0]?.level);
    return aIdx - bIdx;
  });
});

const openSurveyModal = (showSurvey = '') => {
  const AddSurveyDetails = dynamicImport(() => import(/* webpackChunkName: "AddSurveyDetails", webpackPrefetch: true */ 'Components/parts/details/AddSurveyDetails'));
  store.dispatch('openModal', {
    name: 'AddSurveyDetails',
    component: AddSurveyDetails,
    dataObject: { props: { showSurvey } },
    size: 'xlarge',
  });
};

const openAddBoardModal = () => {
  const AddBoardDetails = dynamicImport(() => import(/* webpackChunkName: "AddBoardDetails", webpackPrefetch: true */ 'Components/parts/details/AddBoardDetails'));
  store.dispatch('openModal', {
    name: 'AddSurveyDetails',
    component: AddBoardDetails,
    position: 'center',
    callback: (newDataObject) => {
      if (newDataObject?.boardId) {
        router.push(
          { name: 'board', params: { boardId: newDataObject?.boardId, segmentId: newDataObject?.segmentId } },
        ).catch((err) => {});
      }
    },
  });
};
</script>
