import ga from '@/plugins/googleAnalytics';
import { identify as identifySentry } from '@/plugins/sentry';
import murmurhash from 'Utils/murmurhash';
import { getBaseUrl, putUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

// const SLACK_URL = 'https://hooks.slack.com/services/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';

function me() {
  return getBaseUrl('/feedback/accounts/api/user/me/')
    .then((response) => response.body, handleAuthError)
    .then((user) => {
      identifySentry(user);
      ga('set', 'userId', murmurhash(`${user.id}`, 31));
      return user;
    });
}

function update(id, details) {
  return putUrl(`/feedback/accounts/api/user/${id}/`, details).catch((err) => Promise.reject(err));
}

export default {
  me,
  update,
};
