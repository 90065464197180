<template>
  <div>
    <apexchart
      v-if="!apexImg"
      ref="apex"
      :height="chartOptions.chart.height"
      :type="chartType"
      :options="chartOptions"
      :series="chartData.series"
      @vue:mounted="fixTripleRenderBug"
    />
    <img
      v-if="isPrintViewRouteAndLineGraph && apexImg"
      :src="apexImg"
    >
  </div>
</template>

<script>
/**
 * PROPS:
 * chartData:
 *  ├╼ series: Array [          | Array of line series objects
 *  │   └╼ {
 *  │       name: String,       | Name of the dataseries (the line) e.e 'segment'
 *  │       data: Array,        | Y-values for this series
 *  │       } ,]
 *  │
 *  ┝╼ labels: Array       | X-values for this chart axis
 *  │
 *  ┝╼ type: String             | Type of chart: line, area, bar, pie, donut, scatter, bubble, heatmap, radialBar, candlestick
 *  │
 *  ┝╼ baseValues: String       | if yesno, shows what is selected.
 *  │
 *  └╼ id: Number (same as question id) for destruction or reset methods
 * */
import Apexchart from 'vue3-apexcharts';
import { merge, isNumber, isArray } from 'lodash-es';
import { mapState } from 'vuex';
import { blueColors, topicColors, topicColorsArrayed, creamColorsReverse } from 'Utils/chartColors';
import { roundNumber, translateBaseValue } from 'Utils/general';
import { getApexChartBase64 } from 'Utils/apexchart';
import { KEY_METRIC_GRAPH_TYPES, LINE_LIMIT } from 'Utils/graph';

const fontFamily = "'Trim-Regularr', sans-serif";
const fontFamilyBold = "'Trim-Semmi', sans-serif";

export default {
  name: 'TCChart',
  components: {
    Apexchart,
  },
  props: {
    overwriteChartData: Object,
    chartData: {
      type: Object,
      default: () => ({
        series: null,
        labels: null,
        questionType: null,
        baseValues: null,
        type: null,
        id: null,
        keys: null,
      }),
    },
    type: {
      type: String,
      default: 'line',
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
    },
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      apexImg: '',
    };
  },
  expose: ['destroy'],
  computed: {
    ...mapState({
      shownCardIds: (state) => state.cards.shownCardIds || [],
    }),
    hasHiddenLines() {
      return this.isLinegraph && this.card?.metadata?.show.graph.series?.some((serie) => serie?.visible === false);
    },
    isResponseLine() { return this.card?.metadata.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type === 'line'; },
    isListLines() { return this.isLinegraph && (this.question?.question_type === 'list' || this.question?.question_type === 'listmany'); }, // Should probably be isGroupedBy instaed of isListLines,
    useLogarithmic() {
      return this.chartData.questionType === 'text';
    },
    useBenchmark() {
      return this.chartData?.series.find((t) => t?.name?.toLowerCase() === 'global' || t?.name?.toLowerCase() === 'benchmark');
    },
    isLinegraph() {
      return this.chartType === 'line';
    },
    isPrintViewRouteAndLineGraph() {
      return this.$route?.name?.indexOf('print-') > -1 && this.isLinegraph;
    },
    chartType() {
      return this.chartData.type || this.type;
    },
    lineColor() {
      if (!this.hasData) return this.repeat('#e6e6e6'); // new Array(3).fill('#e6e6e6');

      if (this.chartData.questionType === 'text') {
        let colors = [];
        this.chartData.keys.forEach((key) => colors.push(topicColors[key] || '#e6e6e6'));
        return colors;
      }
      let colorArr = [];
      if (this.isListLines) colorArr = topicColorsArrayed[this.chartData.series.length];
      else if (!this.useBenchmark) colorArr = creamColorsReverse[3].slice(1);
      else colorArr = creamColorsReverse[this.chartData.series.length];

      return isArray(this.chartData.series) && this.chartData.series.length > 0
        ? colorArr
        : blueColors[3];
    },
    lineGraphOptions() {
      // Optimally all options that are not defaults for all apex charts should not be here, but in the component that uses this component
      // this should probably be moved to a separate wrapper component for linegraph to set defaults there, since we now have linegraphs in responseRate and in queryResultChart.
      if (!this.isLinegraph) return {};
      return {
        chart: {
          dropShadow: {
            enabled: true,
            color: this.lineColor,
            opacity: 0.20,
            top: 6,
            blur: 4,
          },
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: this.lineColor?.[0] || '#e6e6e6',
                opacity: 0.4,
              },
              stroke: {
                color: this.lineColor?.[1] || '#e6e6e6',
                opacity: 0.4,
                width: 1,
              },
            },
          },
        },
        grid: {
          show: true,
          borderColor: 'rgba(0, 0, 0, 0.2)',
          padding: {
            top: 0,
            right: 16,
            bottom: 16,
            left: 16,
          },
        },
        legend: {
          formatter: (seriesName, opts) => {
            if (this.isListLines && opts.seriesIndex < LINE_LIMIT && this.hasData) {
              const isHasMoreLinesLegend = !this.chartData.series[opts.seriesIndex]?.label?.length
              || this.chartData.series?.[opts.seriesIndex]?.name
              && this.chartData.series?.[opts.seriesIndex]?.name !== seriesName;
              const isPlaceholder = seriesName === `series-${opts.seriesIndex + 1}`;
              return (isPlaceholder || isHasMoreLinesLegend)
                ? seriesName
                : translateBaseValue(seriesName, this.question);
            }
            return seriesName;
          },
          showForSingleSeries: this.chartData.series.length,
          inverseOrder: !!this.useBenchmark || this.isResponseLine,
          offsetY: 0,
          onItemClick: {
            toggleDataSeries: true,
          },
        },
        markers: {
          strokeColors: '#fff',
          size: this.useBenchmark ? [0.01, ...this.repeat(5)] : [5, ...this.repeat(5)],
          discrete: [],
          strokeWidth: 3,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 0.8,
          shape: 'circle',
          showNullDataPoints: true,
          hover: {
            sizeOffset: 2,
          },
        },
        states: {
          normal: { filter: { type: 'none', value: 0 } },
          active: { filter: { type: 'darken', value: 0.75 } },
          hover: { filter: { type: 'darken', value: 0.75 } },
        },
        stroke: {
          show: true,
          curve: this.useBenchmark ? ['smooth', ...this.repeat('straight')] : ['straight', ...this.repeat('straight')], // ['smooth', 'straight', 'stepline']
          lineCap: 'butt', // round, butt, square
          colors: undefined,
          width: 2,
          dashArray: this.useBenchmark ? [[9, 6], ...this.repeat(0)] : [0, ...this.repeat(0)],
        },
        tooltip: {
          enabled: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily,
          },
          x: {
            show: true,
          },
          y: {
            formatter: ((qType) => (value, {
              series, seriesIndex, dataPointIndex, w,
            }) => {
              const chartDataSerie = this.chartData?.series?.[seriesIndex];
              const count = chartDataSerie?.count?.[dataPointIndex] || null;
              const valueAmount = count ? roundNumber((count / 100) * value) : null;
              const total = this.chartData?.totalCountSeries?.get(chartDataSerie?.label?.[dataPointIndex]) || null;
              if (series[seriesIndex].length === 0) return '–';
              if (count) {
                if (isNumber(value) && this.isResponseLine) return this.$gettextInterpolate(this.$pgettext('Chart - responseRate line (count)', '%{value}% ( %{count} / %{total} svar )'), { value, count, total: (chartDataSerie.totalCount?.[dataPointIndex] || null) });
                if (isNumber(value) && qType === 'yesno') return this.$gettextInterpolate(this.$pgettext('Chart - yesno (count)', '%{value}% ( %{valueAmount} / %{count} svar )'), { value, count, valueAmount });
                if (isNumber(value) && qType === 'text') return this.$gettextInterpolate(this.$pgettext('Chart - text (count)', '%{value} svar'), { value, count, valueAmount });
                if (isNumber(value) && qType === 'list' || isNumber(value) && qType === 'listmany') return this.$gettextInterpolate(this.$pgettext('Chart - list/listmany (count)', '%{value}% ( %{count} / %{total} svar )'), { value, count, total });
                if (isNumber(value)) return this.$gettextInterpolate(this.$pgettext('Chart - other (count)', '%{value} (%{count} svar)'), { value, count });
              } else {
                if (isNumber(value) && this.isResponseLine) return this.$gettextInterpolate(this.$pgettext('Chart - responseRate line', '%{value}%'), { value });
                if (isNumber(value) && qType === 'yesno') return this.$gettextInterpolate(this.$pgettext('Chart - yesno', '%{value}%'), { value });
                if (isNumber(value)) return value;
              }
              return '–';
            })((this.chartData.questionType)),
            title: {
              formatter: (seriesName, { series, seriesIndex, dataPointIndex, w }) => {
                if (this.isListLines && seriesIndex < LINE_LIMIT && this.hasData) {
                  const serie = this.chartData.series.find((t) => t.name === seriesName);
                  const isHasMoreLinesLegend = this.chartData.series[series.length - 1]?.name === serie.name
                    && !serie.label.length;
                  const isPlaceholder = seriesName === `series-${seriesIndex + 1}`;
                  return (isPlaceholder || isHasMoreLinesLegend)
                    ? seriesName
                    : translateBaseValue(seriesName, this.question);
                }
                return seriesName;
              },
            },
          },
        },
        xaxis: {
          type: 'category',
          // categories: These are injected from parseToqResults via overwriteChartData
          categories: this.chartData?.labels ?? [],
          axisBorder: {
            show: false,
          },
          labels: {
            offsetY: 2,
            style: {
              fontSize: '14px',
            },
          },
          tooltip: {
            style: {
              fontSize: '14px',
              fontFamily: fontFamilyBold,
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          logarithmic: false, // this.useLogarithmic
          show: true,
          decimalsInFloat: 1,
          labels: {
            offsetX: 0,
          },
          title: {
            text: this.chartData.questionType === 'yesno' && this.type !== 'bar'
              ? this.$gettextInterpolate(this.$pgettext('Chart: y-axis label', 'Andel (%{baseValues})'), { baseValues: this.chartData.baseValues })
              : undefined,
            rotate: 90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#B3C1C1',
              fontSize: '15px',
              fontFamily: fontFamilyBold,
              fontWeight: 500,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
        },
      };
    },
    chartOptions() {
      const { chart: lineGraphChart, ...rest } = this.lineGraphOptions;
      return merge({}, {
        chart: {
          animations: {
            enabled: false,
            // enabled: this.isPrintViewRouteAndLineGraph,
            // easing: 'easeinout',
            // speed: this.isPrintViewRouteAndLineGraph ? 1 : 800,
            // animateGradually: { enabled: false, delay: this.isPrintViewRouteAndLineGraph ? 1 : 150 },
            // dynamicAnimation: { enabled: true, speed: this.isPrintViewRouteAndLineGraph ? 1 : 350 },
          },
          dropShadow: {
            enabled: false,
          },
          fontFamily,
          foreColor: '#769BA3', // text color,
          type: this.chartType,
          width: '100%',
          height: '400px',
          id: this.chartData.id || '0',
          toolbar: {
            autoSelected: 'zoom',
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false, // '<i class="cursor-none"></i>',
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: '<i class="zmdi zmdi-refresh-alt"></i>', //  If you want to display a custom icon for reset
            },
          },
          ...(this.isLinegraph ? lineGraphChart : {}),
        },
        colors: this.lineColor, // ? optionally functions:[({ value, seriesIndex, w }) => value < 0 ? '#0f0' : '#000']
        noData: {
          text: this.hasData && this.hasHiddenLines
            ? this.$pgettext('Chart - has data but lines are hidden', 'Linjerna med data är dolda, tryck på knapparna nedanför för att visa.')
            : this.$pgettext('Chart - no data', 'Det finns inte tillräckligt med data för att visa en graf.'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: blueColors[1][1],
            fontSize: '14px',
            fontFamily: fontFamilyBold,
          },
        },
        ...(this.isLinegraph ? rest : {}),
      }, this.overwriteChartData);
    },
    hasDataAndcardIsVisible() {
      return this.hasData && this.shownCardIds.includes(this.card.id) || false;
    },
  },
  watch: {
    hasDataAndcardIsVisible: {
      immediate: true,
      handler(newVal) {
        if (this.isPrintViewRouteAndLineGraph && newVal && this.$refs?.apex) this.generateApexPng(this.$refs.apex);
      },
    },
  },
  beforeUnmount() {
    this.$refs?.apex?.destroy?.();
    this.destroy();
  },
  methods: {
    async generateApexPng(chartInstance) {
      this.apexImg = await getApexChartBase64(chartInstance);
    },
    fixTripleRenderBug() {
      this.$nextTick(() => {
        if (typeof window !== 'undefined') window.dispatchEvent(new Event('resize')); // ? Bugfix for vue3-apexcharts triple-rendering https://github.com/apexcharts/vue3-apexcharts/issues/3
      });
    },
    repeat(x, n = 1) {
      const len = this.chartData.series && this.chartData.series.length || 1;
      const arrLength = len - n;
      if (!arrLength) return [x];
      return new Array(arrLength).fill(x);
      // length -2 if series contain trend + global (could be parameterized)
    },
    destroy() {
      if (this.$refs?.apex) this.$refs.apex?.destroy?.();
    },
  },
};
</script>
