<template>
  <div
    v-tc-loader-bar="loading"
    class="query-result-chart tc-loader-bar-wrapper "
    :class="{ 'query-result-no-graph': !hasData && !loading }"
    :data-disable-last-legend="hasMoreLegends || null"
  >
    <div
      v-if="!hasData && !loading"
      class="p-card"
    >
      <p class="alert alert-info">
        <i class="zmdi zmdi-info" />
        <span>{{ $gettext('Det finns ingen data. Prova att ändra filter.') }}</span>
      </p>
    </div>
    <tc-chart
      v-else
      ref="tcChart"
      :card="card"
      :has-data="hasData"
      :chart-data="chartData"
      :overwrite-chart-data="overwriteChartData"
      :question="question"
    />
  </div>
</template>

<script>
import { klona } from 'klona';
import { isBoolean, isNumber, forOwn, isEmpty } from 'lodash-es';
import { set, getDaysInMonth } from 'date-fns';
import { mapGetters, mapActions } from 'vuex';
import { translateBaseValue, roundNumber, globalTuplets } from 'Utils/general';
import { placeholderSerie } from 'Utils/generate';
import { format } from 'Utils/dateHelpers';
import eventBus from 'Utils/eventBus';
import { useLineGraph } from 'Composables/useLineGraph';
import { LINE_LIMIT } from '@/utils/graph';
import TcChart from '../graph/TCChart';

const { useUpdateSeries, useSetLegends } = useLineGraph();

export default {
  name: 'QueryResultChart',
  components: {
    TcChart,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    board: Object,
    loading: Boolean,
    hasData: Boolean,
    question: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    toqResponse: Array,
    toqGlobalResponse: Array,
    previewMode: Boolean,
  },
  emits: ['update-drag-area'],
  data() {
    return {
      reverseStats: null,
      abortToken: Math.random().toString(10).substring(2),
      currentMonthLastDay: format(set(new Date(), { date: getDaysInMonth(new Date()) }), 'yyyy-MM-dd'),
      startOfTrustcruit: format(new Date(2015, 10, 1), 'yyyy-MM-dd'),
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentName',
      'customerName',
      'modalCard',
      'answerValues',
    ]),
    hasMoreLegends() {
      const firstLineLimitKeys = this.sliceLineLimit(this.responseKeys);
      const restOfKeys = this.answerValues(this.questionId).length
          && this.answerValues(this.questionId).filter((key) => !firstLineLimitKeys.includes(key)) || [];
      return restOfKeys.length > 0;
    },
    lineLimit() {
      return this.isTextGraph ? 6 : LINE_LIMIT;
    },
    totalRespondentCount() {
      const totalRespondentMap = new Map();
      if (!this.toqResponse?.[0]) return totalRespondentMap;
      Object?.entries(this.toqResponse?.[0] || {})?.forEach(([key, val]) => {
        Object.entries(val || {})?.forEach(([subKey, v]) => {
          if (subKey) {
            if (totalRespondentMap?.has(subKey)) {
              totalRespondentMap.set(subKey, totalRespondentMap.get(subKey) + (v?.respondent_count ?? v?.count ?? null)); // eslint-disable-line max-len
            } else {
              totalRespondentMap.set(subKey, (v?.respondent_count ?? v?.count ?? null));
            }
          }
        });
      });
      return totalRespondentMap;
    },
    questionId() { return this.card.metadata.question; },
    questionType() { return this.question?.options?.cnps ? 'cnps' : this.question.question_type; },
    // showPercentages() {
    //   return !!this.card.metadata.showPercentages;
    // },
    shouldTrim() {
      return !!(this.compiledFilter?.date?.span?.allTime || this.compiledFilter?.date?.unprocessedDate?.span?.allTime);
    },
    baseValues() {
      if (this.cardBaseValues?.length) return this.cardBaseValues;
      return Object.keys(this.toqResponse?.[0] || {}) || [];
    },
    cardBaseValues() {
      return this.card?.metadata?.show?.columns?.baseValues || [];
    },
    showBaseValues() {
      if (this.baseValues?.length === 1) return this.baseValues[0];
      if (this.questionType === 'yesno') return this.reverseStats ? 'nej' : 'ja';
      return '';
    },
    isTextGraph() { return (this.questionType === 'text'); },
    isListLines() { return (this.questionType === 'list' || this.questionType === 'listmany'); }, // Should probably be isGroupedBy instaed of isListLines
    responseKeys() {
      if (this.isTextGraph) return Object.keys(this.toqResponse?.[0] || {});
      if (this.isListLines) {
        return Object.keys(this.toqResponse?.[0] || {}).filter((key, index) => {
          if (this.baseValues?.length) return this.baseValues?.find((serie) => serie === key);
          if (index < this.lineLimit) return true;
          return false;
        })
          .sort((a, b) => {
          // base on cardBaseValues
            const aIndex = this.baseValues.findIndex((serie) => serie === a);
            const bIndex = this.baseValues.findIndex((serie) => serie === b);
            if (aIndex < bIndex) return -1;
            if (aIndex > bIndex) return 1;
            return 0;
          });
      }
      return [];
    },
    responseLabels() {
      return [...Object.values(this.toqResponse?.[0] || {}).reduce((acc, obj) => {
        Object.values(obj || {}).find((q) => {
          if (q?.answer_str) {
            acc.add(q.answer_str);
            return q?.answer_str;
          }
          return false;
        });
        return acc;
      }, new Set())] || [];
    },
    segmentLabel() {
      const filter = { ...this.compiledFilter };
      if (filter?.date) delete filter.date;
      const hasFilter = Object.keys(filter).length;

      return !hasFilter ? this.segmentName || 'Segment' : this.$pgettext('QueryResultChart - Segment label', 'Filtrerad data');
    },
    chartData() {
      let id = null;
      let series = [];
      let questionType = '';
      let baseValues = '';
      let labels = [];
      let keys = [];

      // This is a temporary fix for the bug where wrong data would come through, should probably be solved in QueryResult
      const listDataNotMatching = this.isListLines && !this.responseLabels.includes(this.responseKeys[0]);
      if (listDataNotMatching || !this.questionType) {
        return {
          id, series, questionType, baseValues, labels, keys, totalCountSeries: new Map(),
        };
      }

      if (this.isTextGraph || this.isListLines) {
        const firstLineLimitKeys = this.sliceLineLimit(this.responseKeys);
        const restOfKeys = this.answerValues(this.questionId).length
          && this.answerValues(this.questionId).filter((key) => !firstLineLimitKeys.includes(key)) || [];

        let responses = firstLineLimitKeys.reduce((acc, label) => ([...acc, this.toqResponse?.[0]?.[label]]), []);
        id = `${this.questionId}-${this.abortToken}`;
        questionType = this.questionType;
        baseValues = translateBaseValue(this.showBaseValues, this.question);
        keys = firstLineLimitKeys;

        if (responses.length) {
          if (this.shouldTrim) {
            const amountToRemove = this.findMinToTrim([responses?.[0] || {}, responses?.[1] || {}]);
            if (amountToRemove) { responses = responses.map((res) => this.trimResponse(res, amountToRemove)); }
          }
          const getName = (index) => (this.isTextGraph ? this.responseLabels[index] : this.responseKeys[index]);

          series = responses
            .map((response, index) => this.serie(response || placeholderSerie(), getName(index)))
            .concat(
              this.hasMoreLegends
                ? this.serie(
                  {},
                  this.$ngettext(
                    '%{ extraBaseVals } till dold linje',
                    '%{ extraBaseVals } till dolda linjer',
                    (restOfKeys.length),
                    { extraBaseVals: restOfKeys.length },
                  ),
                )
                : [],
            );
        }
        const sortedSerieLabels = series.map((serie) => serie.label).sort((a, b) => b.length - a.length);

        labels = sortedSerieLabels[0] || sortedSerieLabels[1] || sortedSerieLabels[2] || [];
      } else {
        let segmentResponse = this.toqResponse?.[0];
        let companyResponse = this.toqResponse?.[2];
        let globalResponse = this.toqGlobalResponse?.[0];

        if (this.shouldTrim) {
          const amountToRemove = this.findMinToTrim([segmentResponse || {}, companyResponse || {}]);
          if (amountToRemove) {
            segmentResponse = this.trimResponse(segmentResponse, amountToRemove);
            companyResponse = this.trimResponse(companyResponse, amountToRemove);
            globalResponse = this.trimResponse(globalResponse, amountToRemove);
          }
        }

        id = `${this.questionId}-${this.abortToken}`;
        questionType = this.questionType;
        baseValues = translateBaseValue(this.showBaseValues, this.question);
        let companySerie = this.serie(companyResponse || placeholderSerie(), this.customerName);
        let segmentSerie = this.serie(segmentResponse || placeholderSerie(), this.segmentLabel);
        let globalSerie = this.serie(globalResponse || placeholderSerie(), isEmpty(this.compiledBenchmark) ? 'Global' : 'Benchmark');
        series = [globalSerie, companySerie, segmentSerie];
        labels = globalSerie?.label || companySerie?.label || segmentSerie?.label || [];
      }
      return {
        id, series, questionType, baseValues, labels, keys, totalCountSeries: this.totalRespondentCount,
      };
    },
    overwriteChartData() {
      let yaxis = {};
      switch (this.questionType) {
        case 'yesno':
        case 'list':
        case 'listmany':
          yaxis = {
            max: (max) => {
              if (Math.ceil(max) < 100) return Math.ceil(max);
              if (Math.ceil(max) >= 100) return 100;
              return Math.floor(max);
            },
            min: (min) => (Math.floor(min) >= 0 ? Math.floor(min) : Math.ceil(min)),
            forceNiceScale: false,
            labels: {
              formatter: (val) => (isNumber(val) ? `${roundNumber(val, 1)}%` : '-'),
            },
          };
          break;
        case 'cnps':
        case 'rating':
          yaxis = {
            max: (max) => (Math.ceil(max) <= 10 ? Math.ceil(max * 10) / 10 : Math.floor(max)),
            min: (min) => (Math.floor(min) >= 0 ? Math.floor(min) : Math.ceil(min)),
            forceNiceScale: true,
          };
          break;
        case 'text':
          yaxis = { labels: { formatter: (val) => roundNumber(val, 0) } };
          break;
        default:
          break;
      }
      const grid = {
        xaxis: {
          lines: {
            show: false,
          },
        },
      };

      let charts = {};
      if (!this.loading) {
        charts = {
          events: {
            legendClick: ((context) => (_, seriesIndex) => {
              // it's currently always using thewrong series labels when using topic linegraph
              const clickedLegend = context?.series[seriesIndex].name || '';
              const serieLegends = (klona(this.metaLegends) || []).map((serie) => {
                if (serie.name === clickedLegend) serie.visible = !serie.visible;
                return serie;
              }); // {name, visible}
              useUpdateSeries({
                legends: serieLegends,
                card: this.card,
                board: this.board,
                db: true,
                previewMode: this.previewMode,
              });
            })((this, this.$refs.tcChart?.$refs?.apex)),
          },
        };
      }

      return { yaxis, chart: charts, grid };
    },
    metaLegends() {
      if (this.isListLines) {
        const lineLimitedBaseValues = this.baseValues?.slice(0, LINE_LIMIT) || [];
        return this.card?.metadata?.show?.graph?.series
          .filter((legend) => lineLimitedBaseValues.includes(legend.name)) || [];
      }
      return this.card?.metadata?.show?.graph?.series || [];
    },
    toqLegends() {
      const lineLimitedBaseValues = this.baseValues?.slice(0, LINE_LIMIT) || [];
      return this.chartData.series.reduce((acc, legend, idx) => {
        let isVisible = true;
        if (this.isListLines) {
          if (lineLimitedBaseValues.length) {
            if (lineLimitedBaseValues.includes(legend.name)) isVisible = true;
            else isVisible = false;
          } else if (idx < LINE_LIMIT) isVisible = true;
          else isVisible = false;
        }
        return { ...acc, [legend.name]: isVisible };
      }, {});
    },
    legendsArray() {
      let mismatch = false;
      if (this.metaLegends.length === 0) mismatch = true;
      this.metaLegends.forEach((legend) => {
        if (this.toqLegends[legend.name]?.visible) return;
        mismatch = true;
      });
      if (mismatch) {
        const legends = [];
        const metaLegendsMap = new Map(this.metaLegends.map((legend) => [legend.name, legend.visible]));
        forOwn(
          this.toqLegends,
          (visible, name) => legends.push({ name, visible: metaLegendsMap.get(name) ?? visible }),
        );
        return legends;
      }
      return this.metaLegends;
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        if (!this.hasData) return;

        useSetLegends(
          { legends: this.legendsArray, hasMoreLegends: this.hasMoreLegends, apexRef: this.$refs.tcChart?.$refs?.apex },
        );
      },
    },
    card: {
      deep: true,
      handler() {
        useSetLegends(
          { legends: this.legendsArray, hasMoreLegends: this.hasMoreLegends, apexRef: this.$refs.tcChart?.$refs?.apex },
        );
      },
    },
    loading(newVal) {
      if (newVal) this.$emit('update-drag-area');
    },
    toqResponse: {
      deep: true,
      handler(response) {
        if (this.isListLines) { this.fetchAnswerValues({ answerKeys: [this.questionId] }); }
        useUpdateSeries({
          legends: this.legendsArray,
          card: this.card,
          board: this.board,
          db: false,
          previewMode: this.previewMode,
        });
        if (this.questionType === 'yesno') {
          forOwn(response[0], (o) => {
            if (o !== null && !isBoolean(this.reverseStats) && isBoolean(o.reverse_stats)) {
              this.reverseStats = o.reverse_stats;
            }
          });
        }
      },
    },
    toqGlobalResponse: {
      deep: true,
      handler() {
        // if no metadata-, or corrupted legends , save from toqresponse, else check what it is.
        useUpdateSeries({
          legends: this.legendsArray,
          card: this.card,
          board: this.board,
          db: false,
          previewMode: this.previewMode,
        });
        useSetLegends({
          legends: this.legendsArray,
          hasMoreLegends: this.hasMoreLegends,
          apexRef: this.$refs.tcChart?.$refs?.apex,
        });
      },
    },
    // segmentId(newId, oldId) {
    //   if (newId !== oldId && this.$refs.tcChart) {
    //     this.$refs.tcChart.destroy();
    //   }
    // },
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
    if (this.$refs.tcChart) { this.$refs.tcChart.destroy(); }
  },
  mounted() {
    useSetLegends({
      legends: this.legendsArray,
      hasMoreLegends: this.hasMoreLegends,
      apexRef: this.$refs.tcChart?.$refs?.apex,
    });
  },
  methods: {
    ...mapActions([
      'setModalCardPath',
      'updateCard',
      'fetchAnswerValues',
    ]),
    sliceLineLimit(arr = []) {
      return arr.slice(0, this.lineLimit) || [];
    },
    findMinToTrim(arr = []) {
      let min;
      let firstTruthyVal;
      arr.forEach((ele, index) => {
        firstTruthyVal = Object.values(ele).findIndex((firstValue) => firstValue);
        if (index === 0 || firstTruthyVal < min) min = firstTruthyVal;
      });
      return min;
    },
    trimResponse(object = {}, trimSize = 0) {
      return Object.keys(object || {})
        .slice(trimSize)
        .reduce((result, key) => {
          result[key] = object[key];
          return result;
        }, {});
    },
    calcTuplet(ja, nej) {
      const yes = ja?.respondent_count ?? ja?.count ?? 0;
      const no = nej?.respondent_count ?? nej?.count ?? 0;
      let total = yes + no;
      if (total === 0) return null;
      return this.reverseStats ? no / total : yes / total;
    },
    roundIfNum(number, qtype) {
      let decimal;
      switch (qtype) {
        case 'rating':
          decimal = 2;
          break;
        case 'list':
        case 'listmany':
        case 'cnps':
        case 'yesno':
        default:
          decimal = 0;
      }
      if (isNumber(number)) return roundNumber(number, decimal);
      return null;
    },
    serie(response, name) {
      const count = [];
      const data = [];
      const label = [];
      forOwn(response, (val, key) => {
        switch (this.questionType) {
          case 'cnps':
            data.push(val?.score != null ? this.roundIfNum(val.score * 100, this.questionType) : null);
            break;
          case 'rating':
            data.push(val?.score != null ? this.roundIfNum(val.score, this.questionType) : null);
            break;
          case 'text':
            data.push(val?.respondent_count ?? val?.count ?? 0);
            break;
          case 'yesno':
            if (val?.score === undefined && (val?.ja || val?.nej)) {
              // eslint-disable-next-line max-len
              data.push(this.roundIfNum(globalTuplets(this.calcTuplet(val.ja, val.nej), this.reverseStats)[this.showBaseValues], this.questionType));
            } else {
              data.push(val?.score != null
                ? this.roundIfNum(globalTuplets(val.score, this.reverseStats)[this.showBaseValues], this.questionType)
                : null);
            }
            break;
          case 'list':
          case 'listmany':
            if ((val?.respondent_count ?? val?.count ?? null) && this.totalRespondentCount?.has(key)) {
              data.push(
                this.roundIfNum(
                  (val?.respondent_count ?? val?.count ?? null) / this.totalRespondentCount.get(key) * 100,
                  this.questionType,
                ),
              );
            } else { data.push(null); }
            break;
          default:
        }
        if ((val?.ja || val?.nej)) count.push(((val?.nej?.respondent_count ?? val?.nej?.count ?? 0) + (val?.ja?.respondent_count ?? val?.ja?.count ?? 0)) || null); // eslint-disable-line max-len
        else count.push(val?.respondent_count ?? val?.count ?? null);
        label.push(val?.date_str || key);
      });
      return {
        count, data, label, name,
      };
    },
  },
};
</script>
