import { pickBy, identity, isPlainObject, isArray } from 'lodash-es';
import { postBaseUrl, postUrl, getUrl } from 'Utils/api';
import { TOQFilter } from 'Utils/apiHelpers';
import { handleAuthError } from 'Utils/authHelpers';
import { convertDateObjectToQueryDates } from 'Utils/date';
import eventBus from 'Utils/eventBus';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

/**
 ** Type Definitions
 * @typedef {(IFilter|IGroupBy)} IDataQuery
 * @typedef {IDataQuery[]} IDataQueries
 */

/**
 ** requestData - supports multiple queries
 *
 * @param  {IDataQueries} queries , An array of IDataQueries, which contains multiple IDataQuery's, an IDataQuery is made up of `filter` & `groupBy`
 * @example
 * [{IFilter, IGroupBy}, { IFilter, IGroupBy }]
 *
 * @param  {(string|number)} [abortToken=null] , A hash token to use for identifying the request, so that it can be aborted via superagent
 * @param  {string|boolean} errorMessage , A translated errorMessage for the error notification, false to disable notification
 *
 * @return {(undefined|object)} response , The response from the server
 */
export async function requestData(queries = [], path = '', abortToken = null, errorMessage = $pgettext('Error - Data API', 'data')) {
  const cleanedQueries = queries.filter(identity).map((q) => pickBy({
    filter: q.filter !== null ? TOQFilter(q.filter) : null,
    groupby: q.groupBy ?? null,
    hierarchy: q.hierarchy ?? null,
    // removes props with empty objects
  }, identity)); // filters out nulls
  // ? Return early if any query's filter is unusable or empty
  if (
    !(cleanedQueries.length > 0)
    || cleanedQueries.some((q) => (Object.keys(q?.filter || {}).length === 1 && Object.keys(q.filter).includes('date')))
  ) return {};

  let response;
  try {
    const dataRequest = postBaseUrl(`/data/${path}`, cleanedQueries);

    if (abortToken) {
      eventBus.$once(`abortRequest:${abortToken}`, () => {
        if (dataRequest.xhr && dataRequest.xhr.status === 0) dataRequest.abort();
      });
    }

    const dataResponse = await dataRequest;
    response = dataResponse.body;
  } catch (err) {
    if (err.code !== 'ABORTED' && !errorMessage) {
      store.dispatch('notify', {
        text: $gettext('Kunde inte hämta %{msg}', { msg: errorMessage }),
        level: 'warning',
        type: 'slow',
      });
      // handleServerError(err);
    }
  }
  return response;
}

export function getExport(exportId) {
  return getUrl(`/data/export/${exportId}/`)
    .catch(handleAuthError);
}

export function exportData(customerId, dateObject, proxies) {
  const { dateGte, dateLte } = convertDateObjectToQueryDates(dateObject);
  const queries = {
    filter: {
      customer_proxy: {
        customer_id: customerId,
        ...(proxies.length > 0 && { customer_proxy_id: proxies }),
      },
      date: {
        $gte: dateGte,
        $lte: dateLte,
      },
    },
  };
  return postUrl('/data/export/', queries)
    .catch(handleAuthError);
}

export function formatFiltersForRequest(filter = {}) {
  if (!isPlainObject(filter)) return {};
  return Object.entries(filter).reduce((acc, [key, val]) => {
    switch (key) {
      case 'customerProxies':
        acc = {
          ...acc,
          customer_proxy: {
            customer_proxy_id: val,
          },
        };
        return acc;
      case 'level':
      case 'step':
        acc = { ...acc, question: { ...acc?.question, step: val } };
        return acc;
      case 'answers':
        acc = { ...acc, answers: val };
        return acc;
      case 'tags':
        if (filter.tags) {
          acc = { ...acc, tags: val };
        }
        return acc;
      case 'topics':
        if (filter.topics) {
          acc = { ...acc, topics: val };
        }
        return acc;
      case 'segment':
        if (isArray(val)) {
          acc = { ...acc, segment: { segment_id: val.map((segment) => segment?.id || segment?.value || segment) } };
        }
        return acc;
      default:
        acc = { ...acc, [key]: val };
        return acc;
    }
  }, {});
}

export default requestData;
