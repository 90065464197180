// Depending on how many colors are needed, here are two sets of color charts
export const purpleColors = [
  [null],
  ['#e9668c'], // 1
  ['#ee8695', '#a73c8f'], // 2
  ['#ee8695', '#a73c8f', '#501b73'], // 3
  ['#f19a9b', '#d54d88', '#7b2a95', '#461766'], // 4
  ['#f4aea3', '#e8638b', '#a73c8f', '#61218d', '#3c1357'], // 5
  ['#f6b5a4', '#eb7590', '#c8488a', '#872e93', '#581d7f', '#3a1453'], // 6
  ['#f7bba6', '#ed8495', '#e05286', '#a73c8f', '#6f2497', '#511b75', '#37114e'], // 7
  ['#f8c1a8', '#ef9198', '#e8608a', '#c0468a', '#8f3192', '#63208f', '#4b186c', '#33104a'], // 8
  ['#f8c7aa', '#f19b9c', '#ea708e', '#d54d88', '#a73c8f', '#7a2995', '#5b2084', '#451764', '#301045'], // 9
  ['#f9cdac', '#f2a49f', '#ec7c92', '#e65586', '#bc438b', '#933391', '#692398', '#551c7b', '#41155e', '#2e0e41'], // 10
  ['#f9cdac', '#f3a8a1', '#ed8495', '#e8608a', '#cd4a89', '#a73c8f', '#822c94', '#63208f', '#511b75', '#3f135b', '#2e0e41'], // 11
  ['#f9cdac', '#f3aca2', '#ee8b96', '#e96a8d', '#db5087', '#b8428c', '#973490', '#742796', '#5e1f88', '#4d1a70', '#3d1358', '#2e0e41'], // 12

];
export const blueColorsReverse = [
  [null],
  ['#5ebcd2'], // 1
  ['#85cbcf', '#3884b6'], // 2
  ['#85cbcf', '#3884b6', '#1d2e81'], // 3
  ['#9ed5cd', '#43a7cb', '#2e63a1', '#192575'], // 4
  ['#b7dfcb', '#5abad1', '#3884b6', '#264992', '#162063'], // 5
  ['#bee0cc', '#71c3d0', '#409dc5', '#316ba7', '#223b89', '#151e5e'], // 6
  ['#c6e3cb', '#83cacf', '#48aed0', '#3884b6', '#2c5a9c', '#1d3082', '#141c58'], // 7
];

export const blueColors = [
  [null],
  ['#5ebcd2'], // 1
  ['#3884b6', '#85cbcf'], // 2
  ['#1d2e81', '#3884b6', '#85cbcf'], // 3
  ['#192575', '#2e63a1', '#43a7cb', '#9ed5cd'], // 4
  ['#162063', '#264992', '#3884b6', '#5abad1', '#b7dfcb'], // 5
  ['#151e5e', '#223b89', '#316ba7', '#409dc5', '#71c3d0', '#bee0cc'], // 6
  ['#141c58', '#1d3082', '#2c5a9c', '#3884b6', '#48aed0', '#83cacf', '#c6e3cb'], // 7
];

// gathered from https://spectrum.adobe.com/page/color-for-data-visualization/
export const sequentialCerulean = ['#85D5CA', '#6CC5C0', '#58B4B9', '#44A2B1', '#3091AA', '#2880A0', '#246E96', '#215D8B', '#1D4C81', '#1C3B77', '#1B296D'];

export const creamColorsReverse = [
  [null],
  ['#A13D63'], // global
  ['#A13D63', '#2E62A1'], // global, segment
  ['#A13D63', '#2E62A1', '#009688'], // global, customer, segment
  ['#A13D63', '#2E62A1', '#009688', '#ee8695'], // global, customer, segment, trend
];
export const creamColors = [
  [null],
  ['#A13D63'], // global benchmark
  ['#2E62A1', '#A13D63'], // customer, global
  ['#009688', '#2E62A1', '#A13D63'], // segment, customer,  global
  ['#ee8695', '#009688', '#2E62A1', '#A13D63'], // trend,  segments, customer, global
];

// export const purpColors = [
//   [null],
//   ['#A13D63'], // global benchmark
//   ['#1d2e81', '#A13D63'], // segment, global
//   ['#1d2e81', '#ee8695', '#A13D63'], // segments, customers, global
// ];

export const cnpsColors = [
  '#ff5555', // red
  '#ffbf0d', // yellow
  '#02ad8c', // green
];

export const topicColors = {
  JOB: '#B38FE6', // '#DDB4FF',
  FAC: '#6BAFEA', // '#9CD8FE',
  DAI: '#6DCD8A', // '#8AEBB5',
  ATS: '#FF8A80', // '#FFB9B4',
  OTH: '#FFA514', // '#FFC25F',
  NRV: '#F5D94C', // '#FEDF84',
  UDM: '#7F7F7F', // '#AAAAAA',
  EXTRA1: '#A67C52',
  EXTRA2: '#62E3DB',
  EXTRA3: '#E1ADB4',
  // EXTRA4: '#FEE2FF',
};

const topicColorsArr = Object.values(topicColors);
export const topicColorsArrayed = topicColorsArr.map((_, idx) => {
  if (idx === 0) return [null];
  return topicColorsArr.slice(0, idx);
});

// ? More colors from https://nivo.rocks/guides/colors/
// const categoricalColors = [
//   rgb(141, 211, 199),
//   rgb(255, 255, 179),
//   rgb(190, 186, 218),
//   rgb(251, 128, 114),
//   rgb(128, 177, 211),
//   rgb(253, 180, 98),
//   rgb(179, 222, 105),
//   rgb(252, 205, 229),
//   rgb(217, 217, 217),
//   rgb(188, 128, 189),
//   rgb(204, 235, 197),
//   rgb(255, 237, 111)
// ];

/**
  * brighten:
  * @param {String} hex - A hex color code e.g. '#ccc'
  * @param {Number} percent - How many percent brighter the color should be e.g. 20
  *
  * @return {String} - A hex color code e.g. '#eee'
  */
export function brighten(hex, percent) {
  hex = hex.replace(/^\s*#|\s*$/g, '');
  let r = parseInt(hex.substr(0, 2), 16);
  let g = parseInt(hex.substr(2, 2), 16);
  let b = parseInt(hex.substr(4, 2), 16);
  /* eslint-disable no-bitwise */
  return `#${
    ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1)}${
    ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1)}${
    ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1)}`;
}
