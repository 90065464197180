<template>
  <div>
    <div class="page-content-title">
      <span class="h2">
        <i class="zmdi zmdi-forms-page" />
      </span>
      <span>
        <h2>{{ $gettext('Alla formulär') }}</h2>
      </span>
    </div>
    <compare-date-filter
      :date-label="dateLabel"
      :date-icon="null"
    />
    <hr>
    <placeholder-card
      v-if="store.getters.customerAllSteps.length === 0"
      :placeholder-message="$pgettext(
        'Message — Stepforms placeholder msg',
        'Det finns ingen data här än'
      )"
      placeholder-size="sm"
      card-icon="zmdi-help-outline"
      :card-message="$pgettext(
        'Message — Stepforms card msg',
        'Vänta tills ni fått svar på något formulär eller byt segment'
      )"
      :cta="false"
    />
    <step-form-list
      v-else
      class="tc-loader-bar-wrapper"
      :all-steps="store.getters.customerAllSteps"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { getKeyByDate } from 'Utils/date';
import StepFormList from 'Components/parts/step-form/StepFormList';
import PlaceholderCard from 'Components/parts/PlaceholderCard';
import CompareDateFilter from 'Components/parts/filters/CompareDateFilter';
import { store } from '@/store';
import gettext from '@/gettext';

const { $pgettext } = gettext;

const dateLabel = computed(() => {
  const { date } = store.getters.segmentFilter;
  if (getKeyByDate(date) === 'this_year' || getKeyByDate(date) === 'all_time') return $pgettext('dateLabel (this_year & all_time) — StepForms', 'Visa svarsfrekvens för');
  if (date.type === 'relative') return $pgettext('dateLabel (type: relative) — StepForms', 'Visa svarsfrekvens för');
  return $pgettext('dateLabel (type: absolute)', 'Visa svarsfrekvens');
});
</script>
