<template>
  <div>
    <label class="block">Filter</label>
    <div class="badge-list">
      <ul
        v-if="badges.length > 0"
        class="badge-list plain-list"
      >
        <VMenu
          v-for="item in badges"
          :key="item.slug"
          :shown="badgeRefs?.[item.slug]"
          auto-hide
          theme="select"
          :triggers="['click', 'touch']"
          :auto-boundary-max-size="false"
          :auto-size="false"
          :disabled="isPopperDisabled"
          :container="false"
          :instant-move="true"
          :flip="!(isCard || isTemporary)"
          :strategy="(isCard || isTemporary) ? 'absolute' : 'fixed'"
          :distance="6"
          @apply-show="handleUpdateShown({ item, shown: true })"
        >
          <BadgeListItemFilter
            class="cursor-pointer"
            :item="item"
            :removable="checkIfRemovable(item)"
            tabindex="0"
            :class="{
              'focus-briefly': (isCard || isTemporary)
                && wasOpenedFromFilter(store.state.cards.modalCardOpenedFrom) === item.slug
            }"
            :should-open-badges="shouldOpenBadgesFilter
              || (isCard || isTemporary) && wasOpenedFromFilter(store.state.cards.modalCardOpenedFrom) === item.slug"
            @remove="handleRemove"
            @update:shown="handleUpdateShown"
            @keyup.delete="handleRemove(item)"
            @keyup.enter="handleUpdateShown({ item, shown: !badgeRefs?.[item.slug] })"
            @keyup.esc="handleUpdateShown({ item, shown: false })"
          />

          <template #popper>
            <FilterDropdownsFilterPopper
              :include-group="includeGroup(item)"
              :badge-item="item"
              :dropdown-settings="{ multiselect: true, dropdownFlavor: 'filter' }"
              @selected="handleSelected"
            />
          </template>
        </VMenu>
      </ul>

      <!-- TODO: v-if="Object.keys(selectedFilters).length < TOTAL_SELECTABLE_KEYS_LENGTH" -->
      <VMenu
        auto-hide
        theme="select"
        :triggers="['click', 'touch']"
        :auto-boundary-max-size="false"
        :auto-size="false"
        :container="false"
        :instant-move="true"
        :flip="!(isCard || isTemporary)"
        :strategy="(isCard || isTemporary) ? 'absolute' : 'fixed'"
        :distance="6"
      >
        <button
          v-tooltip="$pgettext('Tooltip - FilterDropdownsFilter plus icon', 'Lägg till ett nytt filter')"
          class="badge-list-btn btn btn-link btn-icon-text hide-print"
          :class="{
            'focus-briefly': (isCard || isTemporary)
              && wasOpenedFromFilter(store.state.cards.modalCardOpenedFrom)
              && !badges.find(
                (item) => item.slug === wasOpenedFromFilter(store.state.cards.modalCardOpenedFrom)
              )
          }"
        >
          <i class="zmdi zmdi-plus" />
          <span class="ml-0.5">{{ $pgettext('Button - FilterDropdowns add', 'Lägg till') }}</span>
        </button>

        <template #popper>
          <FilterDropdownsFilterPopper
            :exclude-groups="excludeGroups"
            :dropdown-settings="{ multiselect: true, dropdownFlavor: 'filter'}"
            @selected="handleSelected"
          />
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { klona } from 'klona';
import { hideAllPoppers } from 'floating-vue';
import { isEqual, isEmpty } from 'lodash-es';
import { usesFilterTypeKey, mergeFilterBoxRow, wasOpenedFromFilter } from 'Utils/filterBox';
import {
  GOAL_GRAPH_TYPES,
  KEY_METRIC_ALLOWLIST_LEVELS,
  KEY_METRIC_GRAPH_TYPES,
  getAllowlistCompiledFilter,
} from 'Utils/graph';
import { translateTermOption } from 'Utils/general';
import FilterDropdownsFilterPopper from 'Components/parts/filter-dropdowns/FilterDropdownsFilterPopper';
import BadgeListItemFilter from 'Components/parts/badges/BadgeListItemFilter';
import gettext from '@/gettext';
import { store } from '@/store';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  filterMetadata: {
    type: Object,
    default: () => ({}),
  },
  isCard: {
    type: Boolean,
    default: false,
  },
  boardId: {
    type: Number,
  },
  isTemporary: {
    type: Boolean,
    default: false,
  },
});

const badgeRefs = ref({});
const clearBadgeRefs = () => { badgeRefs.value = {}; };
const handleUpdateShown = ({ item, shown }) => { badgeRefs.value[item.slug] = shown; };
const removeBadgeRef = (item) => { delete badgeRefs.value[item.slug]; };

const emit = defineEmits(['update:filterMetadata']);

const selectedFilters = computed({
  get: () => props.filterMetadata ?? {}, // From top: simple db store value
  set: (filterMetadata) => {
    if (filterMetadata !== null) emit('update:filterMetadata', filterMetadata); // From bottom: bigger options object
  },
});
const isGoal = computed(() => GOAL_GRAPH_TYPES[store.getters.modalCard?.metadata?.graphType?.selected]);
const isKeyMetricCard = computed(() => store.getters.modalCard?.metadata?.graphType !== 'undefined'
        && Object.values(KEY_METRIC_GRAPH_TYPES).includes(store.getters.modalCard?.metadata?.graphType?.selected));
const stepOptions = computed(() => {
  let options = [];
  if (isKeyMetricCard.value) {
    const { any_step, specific } = KEY_METRIC_ALLOWLIST_LEVELS[store.getters.modalCard.metadata.graphType.selected];
    const customerSteps = store.getters.customerAllStepsOptions.map((option) => {
      if (option?.inactive) return { ...option, sidelabel: $pgettext('Sidelabel — Inactive step', 'Pausat formulär') };
      return option;
    }) || [];
    if (any_step) options = [...options, ...customerSteps];
    else if (specific) options = [...options, ...specific.map(translateTermOption)];
  }
  return options;
});

const includeGroup = (item) => {
  if (item?.boxRowKey === 'tags') return item.label;
  if (item?.boxRowKey === 'answers') return item.label;
  return item?.boxRowKey;
};

const excludeGroups = computed(() => Object.entries(selectedFilters.value).reduce((acc, [key, val]) => {
  if (usesFilterTypeKey(key)) Object.keys(val)?.forEach((vkey) => acc.push(vkey)); // ? Dive into 'tags' & 'answers' deeper
  else acc.push(key);
  return acc;
}, []));

const question = ref(null);
store.dispatch('getQuestionById', store.getters.modalCard?.metadata?.question)
  .then((questionId) => { question.value = questionId; })
  .catch((err) => { question.value = null; });

const cardType = computed(() => {
  if (isKeyMetricCard.value) return 'keyMetric';
  if (isGoal.value) return 'goal';
  return 'question';
});

const isModalCard = computed(() => store.getters?.modalCard?.metadata?.graphType !== undefined);

const cleanFilters = computed(() => {
  if (props.boardId || !isModalCard.value && props.isTemporary) return selectedFilters.value;
  return getAllowlistCompiledFilter({
    filter: selectedFilters.value,
    ...(cardType.value !== 'keyMetric' && { questionType: question.value?.question_type }),
    graphTypeSelected: store.getters.modalCard?.metadata?.graphType?.selected,
    cardType: cardType.value,
  });
});

const badges = computed(() => Object.entries(cleanFilters.value)?.reduce((acc, [key, val]) => {
  switch (key) {
    case 'date': // ? Handled in FilterDropdownsDate.vue
      break;
    case 'tags':
      Object.entries(val)?.forEach(([vkey, vval]) => {
        acc.push({
          slug: `tags__${vkey.toLowerCase().split(' ').join('_')}`,
          label: vkey,
          value: vval,
          boxRowValue: val,
          boxRowKey: key,
          boxRowType: 'tags',
        });
      });
      break;
    case 'topics':
      acc.push({
        slug: 'topics',
        label: $pgettext('Badgelist', 'Textkategori'),
        value: store.getters.topics
          .filter((topic) => val.includes(topic.id))
          .map((topic) => topic.name),
        boxRowValue: val,
        boxRowKey: key,
        boxRowType: null,
      });
      break;
    case 'answers':
      Object.entries(val)?.forEach(([vkey, vval]) => {
        const answerBadgeProm = {
          slug: `answers__${vkey.toLowerCase().split(' ').join('_')}`,
          label: vkey,
          value: vval,
          needsTranslation: true,
          boxRowValue: vval,
          boxRowKey: vkey,
          boxRowType: 'answers',
        };
        acc.push(answerBadgeProm);
      });
      break;
    case 'segment':
      acc.push({
        slug: 'segment',
        label: $pgettext('Badgelist', 'Segment'),
        value: store.getters.customerSegments
          .filter((segment) => val.includes(segment.id))
          .map((segment) => segment.name),
        boxRowValue: val,
        boxRowKey: key,
        boxRowType: null,
      });
      break;
    case 'step':
      if (isKeyMetricCard.value) {
        acc.push({
          slug: 'step',
          label: $pgettext('Badgelist', 'Formulär'),
          value: stepOptions.value
            .filter((step) => val.includes(step.value))
            .map((step) => step.label),
          boxRowValue: val,
          boxRowKey: key,
          boxRowType: null,
        });
      }
      break;
    case 'customerProxies':
      acc.push({
        slug: 'customerProxies',
        label: $pgettext('Badgelist', 'Proxy'),
        value: store.getters.fetchingProxies
          ? $gettext('Laddar data...')
          : store.getters.customerProxies.filter((pval) => val.includes(pval.id))
            .map((pval) => pval.internal_name || pval.name),
        boxRowValue: val,
        boxRowKey: key,
        boxRowType: null,
      });
      break;

    default:
      break;
  }
  return acc;
}, []) ?? []);

const initialBadges = props.isTemporary ? ref([]) : ref(null);
const shouldSetOriginalBadges = !props.isTemporary ? ref(true) : ref(false);

watch(
  () => badges.value,
  (newVal, oldVal) => {
    // watch if badges have changed and if so compare with badgeRefs to see if any have been removed if so remove from badgeRefs
    if (newVal && !isEqual(newVal, oldVal)) {
      Object.keys(badgeRefs.value)?.forEach((key) => {
        if (!newVal?.find((badge) => badge.slug === key)) removeBadgeRef({ slug: key });
      });
    }

    if (shouldSetOriginalBadges.value) {
      initialBadges.value = klona(badges.value);
      initialBadges.value.forEach((badge) => handleUpdateShown({ item: badge, shown: false }));
    }
    if (shouldSetOriginalBadges.value) shouldSetOriginalBadges.value = false;
  },
  { deep: true },
);

watch(
  () => props.boardId,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      initialBadges.value = props.isTemporary ? [] : null;
      shouldSetOriginalBadges.value = true;
    }
  },
  { immediate: true },
);

const shouldOpenBadgesFilter = computed(
  () => initialBadges.value !== null && !isEqual(badges.value, initialBadges.value),
);

const handleSelected = async (options = []) => {
  if (options?.[0]?.isSublist) hideAllPoppers();
  // const allowlistedMetadata = Object.keys(metadata)
  //   .filter((key) => FILTERBOX_ALLOWLISTED_FILTER_TYPES.includes(key))
  //   .reduce((obj, key) => {
  //     obj[key] = metadata[key];
  //     return obj;
  //   }, {});

  // ? Update selectedFilters to be completely up to date
  const boxRow = options?.reduce((acc, option) => {
    // ? Cluster fck because we sometimes add a value, sometimes a key, and we need to get them into a standardized boxRow
    if (option?.isSublist === false) {
      if (usesFilterTypeKey(option?.type)) {
        acc = {
          type: option?.type,
          key: option?.group,
          value: [
            ...acc.value, ...(option?.value ? [option?.value] : []),
          ],
        };
      } else {
        acc = {
          type: option?.group,
          key: option?.group,
          value: [
            ...acc.value, ...(option?.value ? [option?.value] : []),
          ],
        };
      }
    } else if (usesFilterTypeKey(option?.type)) {
      acc = { type: option?.type, key: option?.value, value: [] };
    } else {
      acc = { type: option?.group, key: option?.value, value: [] };
    }

    return acc;
  }, { type: null, key: null, value: [] }) ?? {};

  const newMetadata = mergeFilterBoxRow(options?.[0]?.multiselect !== false ? selectedFilters.value : {}, boxRow);
  selectedFilters.value = newMetadata;
};

const isPopperDisabled = ref(false);
const handleRemove = async (removedBadge) => {
  removeBadgeRef(removedBadge);
  isPopperDisabled.value = true;

  // ? Update selectedFilters to be completely up to date
  let newFilters = klona(selectedFilters.value);
  if (usesFilterTypeKey(removedBadge.boxRowType)) {
    newFilters = Object.entries(newFilters)?.reduce((acc, [key, val]) => {
      if (key !== removedBadge.boxRowType) acc[key] = val;
      else {
        const newVal = Object.entries(val)?.reduce((acc2, [key2, val2]) => {
          if (key2 != removedBadge.boxRowKey && key2 !== removedBadge.label) acc2[key2] = val2; // eslint-disable-line eqeqeq
          return acc2;
        }, {});
        acc[key] = newVal; // Keep metadata.filter.[key] no matter if it's empty or not. (tags & answers will be saved)
      }
      return acc;
    }, {});
  } else if (newFilters?.[removedBadge.boxRowKey]) {
    delete newFilters[removedBadge.boxRowKey];
  }
  selectedFilters.value = newFilters;
  isPopperDisabled.value = false;
};

const timers = ref([]);
onMounted(() => {
  if ((props.isCard || props.isTemporary) && wasOpenedFromFilter(store.state.cards.modalCardOpenedFrom)) {
    timers.value.push(setTimeout(() => {
      Object.keys(badgeRefs.value ?? {}).forEach((key) => {
        handleUpdateShown({
          item: { slug: key },
          shown: key === store.state.cards.modalCardOpenedFrom,
        });
      });
    }, 100));
    timers.value.push(setTimeout(() => store.dispatch('setModalCardOpenedFrom', null), 2000));
  }
});
onUnmounted(() => {
  timers.value.forEach((timer) => clearTimeout(timer));
});

const checkIfRemovable = (item) => {
  if (isEmpty(store.getters.modalCard)) return true;
  if (item.slug === 'step' && KEY_METRIC_ALLOWLIST_LEVELS?.[store.getters.modalCard.metadata.graphType.selected]?.stepRequired) {
    return false;
  }
  return true;
};

defineExpose({
  clearBadgeRefs,
});
</script>
