export class ErrCodeError extends Error {
  constructor(error) {
    super(error?.response?.body?.reason || error?.response?.body?.detail || error?.response?.body || error);
    this.errCode = error?.response?.statusCode || error?.statusCode || error?.status;
  }
}

export default {
  ErrCodeError,
};
